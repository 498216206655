<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 16:04:07
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-28 21:05:43
-->
<template>
  <div v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-immediate-check="false">
    <slot :list="list" :loaded="loaded"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      loaded: false,
      loading: false,
      list: []
    }
  },
  props: {
    query: Object,
    api: Function
  },
  computed: {
    disabled() {
      return this.loading || this.loaded
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const { data } = await this.api({
          page: this.page,
          ...this.query
        })
        this.loading = false
        if (!data.data || data.data.length < 1) {
          this.loaded = true
        }
        this.total = data.total;
        let j = ''
        if(this.query.type){
          j = data.data;
        }else{
          j = data
        }
        this.list = this.list.concat((j || []));
      } catch (e) {
        this.loaded = true
      }
    },
    search() {
      this.page = 1;
      this.loaded = false;
      this.list = []
      this.getData()
    },
    load() {
      if (this.loaded) {
        return
      }
      this.page++
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
export default {
  questionTypeMap: {
    1: '单选题',
    2: '复选题',
    3: '名词解释',
    4: '计算题',
    5: '简答题',
    6: '论述题',
    7: '判断题',
    8: '判断不定项选择题',
    9: '分析题'
  }
}
<template>
  <div class="page-search">
    <div class="module-banner">
      <div class="search-container">
        <input v-model="query.keywords" placeholder="输入关键词搜索题目" type="text" @keyup.enter="search">
        <img class="search-icon" @click="search" src="../../assets/images/icon-search.png" alt="">
      </div>
    </div>
    <div class="search-result">
      <scroll-list ref="list" :api="api" :query="query">
        <div slot-scope="scope">
          <div v-show="scope.list.length>0" class="result-has h-exam-list">
            <div class="title">
              搜索结果
            </div>
            <div class="content">
              <ul>
                <li v-for="item in scope.list" :key="item.id" @click="toQuestion(item.id)">
                  <div class="wrap clearfix">
                    <span class="type-name">
                      {{questionTypeMap[item.type]}}
                    </span>
                    <div class="value text-overflow-clamp-2" v-html="item.contents">
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div v-show="scope.list.length<1&&scope.loaded" class="result-none">
            <div class="icon">
              <img src="../../assets/images/icon-none.png" alt="">
            </div>
            <div class="tip">
              抱歉，没有找到相关内容，可以试试
              <a ref="javascript:;" class="text-main" @click="upload">上传题目</a>
            </div>
          </div>
        </div>
      </scroll-list>
    </div>

    <el-dialog custom-class="h-dialog" width="6rem" title="" :show-close="false" :visible.sync="uploadVisible" append-to-body>
      <upload-answer @cancel="uploadVisible=false" @uploadSuccess="uploadSuccess"></upload-answer>
    </el-dialog>
  </div>
</template>
<script>
import scrollList from '@/components/scrollList'
import uploadAnswer from '@/components/uploadAnswer'
import { getQuestions } from '@/api/exam'
import dic from '@/util/dic'
export default {
  components: {
    scrollList,
    uploadAnswer
  },
  data() {
    return {
      api: getQuestions,
      query: {
        keywords: ''
      },
      questionTypeMap: dic.questionTypeMap,
      uploadVisible: false
    }
  },
  created() {
    this.query.keywords = this.$route.query.keywords;
  },
  methods: {
    search() {
      this.$refs['list'].search()
    },
    upload() {
      if (!this.$store.getters.uniqid) {
        this.$root.Bus.$emit('showLogin')
        return
      }
      this.uploadVisible = true
    },
    uploadSuccess() {
      this.uploadVisible = false
    },
    toQuestion(id) {
      if (!this.$store.getters.uniqid) {
        this.$root.Bus.$emit('showLogin')
        return
      }
      this.$router.push('/question/' + id)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-search {
  padding-bottom: 60px;
  .module-banner {
    padding-top: 60px;
    height: 160px;
    background: url("~@/assets/images/search-banner.png") no-repeat center
      center;
    background-size: auto 100%;
    .search-container {
      position: relative;
      margin: 0 auto;
      width: $contentWidth;
      max-width: 100%;
      height: 50px;
      padding: 13px 54px 13px 20px;
      background: #ffffff;
      border-radius: 10px;
      input {
        width: 100%;
        height: 100%;
        font-size: 18px;
      }
      .search-icon {
        position: absolute;
        top: 13px;
        right: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .search-result {
    margin: 20px auto 0;
    width: $contentWidth;
    max-width: 100%;
    min-height: 840px;
    background: #fff;
  }
  .result-none {
    padding-top: 260px;
    text-align: center;
    .icon {
      line-height: 0;
      img {
        width: 120px;
      }
    }
    .tip {
      margin-top: 28px;
      font-size: 18px;
      a {
        cursor: pointer;
      }
    }
  }
}
</style>
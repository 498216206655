import http from '@/util/http'
export const getQuestions = (data) => http.post('/api/exam/getQuestions', data);
export const getExamCategory = (data) => http.post('/api/exam/getExamCategory', data);
export const getExamPaperList = (data) => http.post('/api/exam/getExamPaperList', data);
export const getExamPaper = (data) => http.post('/api/exam/getExamPaper', data);
export const getExamQuestion = (data) => http.post('/api/exam/getExamQuestion', data);
export const questionDetail = (data) => http.post('/api/exam/questionDetail', data);
export const getPaper = (data) => http.post('/api/exam/getPaper', data);
export const getQuestionComment = (data) => http.post('/api/exam/getQuestionComment', data);
export const setAnswer = (data) => http.post('/api/exam/setAnswer', data);
export const doLike = (data) => http.post('/api/exam/doLike', data);
export const unLike = (data) => http.post('/api/exam/unLike', data);
export const comment = (data) => http.post('/api/exam/comment', data);
